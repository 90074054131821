/* eslint-disable indent,prettier/prettier */
'use client';

import { MissionSwiper } from '@/components/Swipers/Mission';
import { Tags } from '@/components/Tags';
import clsx from 'clsx';
import Link from 'next/link';
import Image from 'next/image';
import { ContentType, IListContent, ISection } from '@/interfaces/layout';
import { VideoPlayer } from '@/components/VideoPlayer/';
import { ReactElement, ReactNode } from 'react';
import { Socials } from '@/components/Socials';
import { Picture } from '@/components/Common/Picture';
import { LoopingVideo } from '@/components/LoopingVideo';
import { AnimationBlock } from '@/components/AnimationBlock';
import { useRef } from 'react';

interface IProps extends ISection {
  titleClassname?: string;
  preTitleClassname?: string;
  contentClassname?: string;
  imageClassname?: string;
  swiper?: {
    list: IListContent[];
  };
  dark?: boolean;
  lens?: ReactElement;
  withTriangle?: boolean;
  optimized?: boolean;
  testId?: string;
  whiteParagraphText?: boolean;
  children?: ReactNode;
  animated?: boolean;
  videoClassname?: string;
  isVideoRounded?: boolean;
  id?: string;
  objectPositionRight?: boolean;
}

// TODO: Decompose and refactor GOD component
export const Section = ({
  id,
  testId,
  tags,
  classname,
  innerContentClassname,
  direction = 'flex-row',
  title,
  content,
  readMoreHref,
  preTitle,
  image,
  video,
  avatar,
  swiper,
  titleClassname,
  imageClassname,
  preTitleClassname,
  contentClassname,
  dark,
  lens,
  withTriangle,
  optimized,
  whiteParagraphText,
  children,
  animated,
  videoClassname,
  isVideoRounded = false,
  objectPositionRight = false,
}: IProps) => {
  const preTitleRef = useRef(null);
  const titleRef = useRef(null);
  const paragraphRef = useRef(null);
  const descriptionRef = useRef(null);

  const preTitleContent = (
    <h3
      data-testid="pre-title"
      className={clsx('intro-pre-title mb-xs', {
        ['text-purple-9']: !preTitleClassname,
        [`${preTitleClassname}`]: preTitleClassname,
      })}
      ref={preTitleRef}
    >
      {preTitle}
    </h3>
  );

  const titleContent = (
    <h4
      data-testid="section-title"
      className={clsx(
        `intro-title mb-xl tracking-[-0.96px] text-3xl leading-[42.2px]
       mobile:text-5xl mobile:leading-[67.2px]`,
        {
          ['text-4xl leading-[44px] tracking-[-1.52px] text-purple-9']: video,
          [`${titleClassname}`]: titleClassname,
          ['text-black-10']: !dark,
          ['text-white max-w-[97%]']: dark,
        },
      )}
      ref={titleRef}
    >
      {title}
    </h4>
  );

  const getParagraphContent = (type: ContentType, content: any) => (
    <p
      key={`content-${type}-${String(content).length}`}
      data-testid={`content-${type}`}
      className={clsx('mb-[32px] last:mb-0 leading-[25.6px] lg:text-[20px]', {
        ['text-black-8']: !dark,
        ['text-quinary']: dark,
        ['text-white']: whiteParagraphText,
      })}
      dangerouslySetInnerHTML={{ __html: content }}
      ref={paragraphRef}
    />
  );

  const renderDescription = (type: ContentType, content: any) => {
    const descriptionMap: { [key: string]: ReactElement } = {
      paragraph: animated ? (
        <AnimationBlock
          key="anim-1"
          id="section-paragraph-anim-1"
          blockRef={paragraphRef}
        >
          {getParagraphContent(type, content)}
        </AnimationBlock>
      ) : (
        getParagraphContent(type, content)
      ),
      element: <div ref={descriptionRef}>{
        animated ? (
          <AnimationBlock
            key="animated-hero-element"
            id="animated-hero-element-2"
            blockRef={descriptionRef}
          >
            {content}
          </AnimationBlock>
        ) : (
          content
        )
      }</div>,
      quote: (
        <p
          key={Math.random().toString()}
          data-testid={`content-${type}`}
          className="border-l-orange-500 pl-xl border-l-2"
        >
          {content}
        </p>
      ),
      list: (
        <ul
          key={Math.random().toString()}
          data-testid={`content-${type}`}
          className="grid grid-cols-1 mobile:grid-cols-2 gap-[32px] mb-[32px] lg:mb-0"
        >
          {[...content].map((item, index, arr) => (
            <li
              key={index}
              className={clsx('', {
                ['mobile:col-span-2']: index === arr.length - 1 && arr.length < 4,
              })}
            >
              <p className="text-black-10 text-xl font-semibold leading-[28px] mb-[8px]">{item.title}</p>
              <p className="text-black-8 leading-[25.6px]">{item.description}</p>
            </li>
          ))}
        </ul>
      ),
      subHeadings: (
        <ul
          key={Math.random().toString()}
          className="flex flex-col mobile:flex-row gap-xl"
          data-testid={`content-${type}`}
        >
          {[...content].map((item, index) => (
            <li
              key={index}
              className="p-xl border-grey-1 border-[1px] rounded-[16px]"
            >
              <h6 className="text-purple-7 font-semibold leading-[19.2px] mb-base">{item.title}</h6>
              <p className="text-black-8 text-sm w-[91%]">{item.description}</p>
            </li>
          ))}
        </ul>
      ),
      twoColumns: (
        <ul
          key={Math.random().toString()}
          className="grid grid-cols-1 mobile:grid-cols-2 gap-xl lg:gap-x-[32px] lg:gap-y-[64px]"
          data-testid={`content-${type}`}
        >
          {[...content].map((item, index) => (
            <li
              key={index}
              className="text-center"
            >
              <p className="text-black-10 text-xl font-semibold leading-[28px] mb-[8px]">{item.subtitle}</p>
              <p className="text-black-8 leading-[25.6px]">{item.description}</p>
            </li>
          ))}
        </ul>
      ),
      icon: (
        <Socials
          type={content}
          key={Math.random().toString()}
        />
      ),
      simpleList: (
        <ul
          key={Math.random().toString()}
          className="mb-[32px]"
          data-testid={`content-${type}`}
        >
          {[...content].map((item, index) => (
            <li key={index}>
              <p className="text-black-10 text-xl font-semibold leading-[28px] mb-[8px]">{item}</p>
            </li>
          ))}
        </ul>
      ),
      unorderedList: (
        <ul
          key={Math.random().toString()}
          className="mb-[32px] list-disc pl-[24px]"
          data-testid={`content-${type}`}
        >
          {[...content].map((item, index) => (
            <li key={index}>
              <p className="text-black-8 leading-[25.6px] mb-[8px]">{item}</p>
            </li>
          ))}
        </ul>
      ),
    };

    return descriptionMap[type] || null;
  };

  return (
    <section
      data-testid={testId}
      className={clsx('', { [`${classname}`]: classname, ['relative overflow-x-clip']: lens })}
    >
      <div
        className={clsx(`flex gap-x-xl mx-auto container`, {
          [`${direction}`]: direction,
          [`${innerContentClassname}`]: innerContentClassname,
          ['xl-1:gap-x-[52px] gap-y-[30px]']: video,
        })}
      >
        <div
          className={clsx('w-full lg:w-[60%] xl-1:w-[51.4%]', {
            ['lg:w-full']: video,
            [`${contentClassname}`]: contentClassname,
          })}
        >
          {tags && <Tags tags={tags} />}
          {preTitle && animated ? (
            <AnimationBlock
              id={`section-paragraph-anim${id ? `-${id}` : id}`}
              key="anim-2"
              blockRef={preTitleRef}
            >
              {preTitleContent}
            </AnimationBlock>
          ) : (
            preTitleContent
          )}
          {title && animated ? (
            <AnimationBlock
              id="section-title-anim"
              blockRef={titleRef}
            >
              {titleContent}
            </AnimationBlock>
          ) : (
            titleContent
          )}
          {video && avatar && (
            <div className="flex mb-xl">
              <div
                data-testid={avatar.image}
                className="w-[52px] h-[52px] rounded-full bg-gray"
              />
              <div className="flex flex-col ml-[14px]">
                <p className="font-semibold leading-[25.68px] text-purple-7">{avatar.name}</p>
                <p className="text-black-10 text-sm leading-[22px]">{avatar.role}</p>
              </div>
            </div>
          )}
          {content &&
            content.map((item) => {
              return renderDescription(item.type as ContentType, item.content);
            })}
          {swiper && (
            <div className="block [@media(min-width:1024px)]:hidden [@media(min-width:1180px)]:block">
              <MissionSwiper list={swiper.list} />
            </div>
          )}
          {readMoreHref && (
            <Link
              scroll={true}
              data-testid="read-more-btn"
              href={readMoreHref}
            >
              Read More
            </Link>
          )}
        </div>
        {image && !video && (
          <div
            className={clsx(
              `h-auto relative
               lg:h-[40%] lg:w-[40%]
               xl-1:h-auto xl-1:w-auto z-[2] 
               rounded-[16px] overflow-hidden
               flex justify-center
             `,
              {
                [`${imageClassname}`]: imageClassname,
              },
            )}
          >
            {optimized ? (
              <Picture
                mainImage={{
                  width: image.width,
                  height: image.height,
                  alt: 'Section',
                  src: image.path,
                }}
                imageClass={clsx('z-[3] object-cover h-full', {
                  'object-right': objectPositionRight,
                })}
                withWebp
                format="jpeg"
              />
            ) : (
              <Image
                src={image.path}
                alt="section"
                width={image.width}
                height={image.height}
                className={clsx('z-[3] object-cover h-full', {
                  'object-right': objectPositionRight,
                })}
                loading="lazy"
                placeholder="empty"
              />
            )}
          </div>
        )}
        {video &&
          (video.looping ? (
            <LoopingVideo
              isPlaying
              src={video.url}
              isVideoRounded={isVideoRounded}
              className={clsx('max-w-[592px] max-h-[352px]', {
                [`${videoClassname}`]: videoClassname,
              })}
              objectPositionRight={objectPositionRight}
            />
          ) : (
            <VideoPlayer src={video.url} />
          ))}
      </div>
      {swiper && (
        <div
          className="
            w-full pt-[40px] px-[15px] hidden
            [@media(min-width:1024px)]:block
            [@media(min-width:1180px)]:hidden
          "
        >
          <MissionSwiper list={swiper.list} />
        </div>
      )}
      {lens}
      {withTriangle && (
        <svg
          width="499"
          height="290"
          viewBox="0 0 855 497"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={`
             absolute bottom-0 hidden 
             lg:right-[-15.1%] lg:block
             [@media(min-width:1280px)]:right-[-16.1%]
             [@media(min-width:1440px)]:right-[-15.1%]
             [@media(min-width:1920px)]:right-[-0.2%]
          `}
        >
          <path
            d="M855 0V497H0L855 0Z"
            fill="white"
          />
        </svg>
      )}
      {children}
    </section>
  );
};
