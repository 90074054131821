import { INavigationLink } from '@/interfaces/navigation';
import clsx from 'clsx';
import Link from 'next/link';

interface IProps extends INavigationLink {
  testID: string;
  classname?: string;
}

export const Tag = ({ label, testID, classname, url }: IProps) => {
  return (
    <span
      className="mr-[4px] last:mr-[8px]"
      data-testid={testID}
    >
      {url ? (
        <Link
          href={{
            pathname: url,
            query: { tags: label },
          }}
        >
          <span
            className={clsx(
              `transition-colors duration-300 ease-out 
               px-[8px] py-[4px] text-[12px]
               font-medium text-black-9  hover:border-primary`,
              {
                [`${classname}`]: classname,
              },
            )}
          >
            {label}
          </span>
        </Link>
      ) : (
        <span
          className={clsx('px-[8px] py-[4px] text-[12px] font-medium text-black-9', {
            [`${classname}`]: classname,
          })}
        >
          {label}
        </span>
      )}
    </span>
  );
};
