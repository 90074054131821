export const BREAKPOINTS = {
  mobile: 496,
  sm: 600,
  md: 728,
  'md-tablet': 769,
  'md-1': 801,
  lg: 1024,
  'lg-plus': 1025,
  'lg-1': 1050,
  'lg-2': 1180,
  'xl-1': 1280,
  xl: 1440,
};
