import { IPicture } from '@/interfaces/layout';
import clsx from 'clsx';

interface IProps {
  mobileResolution?: string;
  mainImage: IPicture;
  format: 'jpeg' | 'png';
  withWebp: boolean;
  pictureClass?: string;
  imageClass?: string;
}

export const Picture = ({
  mobileResolution = '(max-width: 496px)',
  mainImage,
  withWebp,
  format,
  pictureClass,
  imageClass,
}: IProps) => {
  return (
    <picture
      className={clsx('', {
        [`${pictureClass}`]: pictureClass,
      })}
    >
      {withWebp && (
        <source
          media={mobileResolution}
          srcSet={`${mainImage.src}-mobile.webp`}
          type="image/webp"
        />
      )}
      <source
        media={mobileResolution}
        srcSet={`${mainImage.src}-mobile.${format}`}
        type={`image/${format}`}
      />
      {withWebp && (
        <source
          srcSet={`${mainImage.src}.webp`}
          type="image/webp"
        />
      )}
      <img
        src={`${mainImage.src}.${format}`}
        alt={mainImage.alt}
        loading="eager"
        height={mainImage.height}
        width={mainImage.width}
        className={clsx('', {
          [`${imageClass}`]: imageClass,
        })}
      />
    </picture>
  );
};
