'use client';

import { useState, useEffect, PropsWithChildren, RefObject } from 'react';
import { motion } from 'framer-motion';
import { useIsInViewport } from '@/hooks/useIsInViewport';

interface IProps extends PropsWithChildren {
  blockRef: RefObject<Element>;
  id: string;
}

export const AnimationBlock = ({ children, blockRef, id }: IProps) => {
  const [isAnimationCalled, setAnimationCall] = useState(false);

  const isInViewPort = useIsInViewport(blockRef);

  useEffect(() => {
    if (isInViewPort && !isAnimationCalled) {
      setAnimationCall(true);
    }
  }, [isAnimationCalled, isInViewPort]);

  return (
    <motion.div
      id={id}
      initial={{ y: 75, opacity: 0 }}
      animate={isAnimationCalled ? { y: 0, opacity: 1 } : {}}
      transition={{
        y: { duration: 0.7, ease: 'easeOut', delay: 0.3 },
        opacity: { duration: 1.2, ease: 'easeOut', delay: 0.3 },
      }}
    >
      {children}
    </motion.div>
  );
};
