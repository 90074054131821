import { INavigationLink } from '@/interfaces/navigation';
import { Tag } from '@/components/Tags/Tag';

interface IProps {
  tags: INavigationLink[];
  tagClassname?: string;
}

export const Tags = ({ tags, tagClassname }: IProps) => {
  return (
    <div className="mb-[24px]">
      {tags.map(({ url, label }, index) => {
        return (
          <Tag
            key={index.toString()}
            url={url}
            label={label}
            testID={`section-tag-${label}`}
            classname={tagClassname!}
          />
        );
      })}
    </div>
  );
};
