/* eslint-disable max-len */
import React from 'react';
import { formatDuration } from '@/helpers/formatters';

interface IProps {
  icon: boolean;
  isPlaying: boolean;
  onPlayPause: () => void;
  loading: boolean;
  handleProgressBarClick: (e: React.MouseEvent<HTMLElement>) => void;
  progressBarRef: any;
  playerRef: any;
  played: number;
  startTime: number;
}

export const Controls = ({
  icon,
  isPlaying,
  onPlayPause,
  loading,
  handleProgressBarClick,
  progressBarRef,
  playerRef,
  played,
  startTime,
}: IProps) => {
  return (
    <>
      {icon && (
        <div
          onClick={onPlayPause}
          className="absolute h-[80px] w-[80px] bg-white50 rounded-full top-[50%] left-[50%] transform translate-x-[-50%] translate-y-[-50%] cursor-pointer"
        >
          {isPlaying ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              id="Pause"
              width="16"
              height="16"
              className="absolute top-[50%] left-[50%] transform translate-x-[-50%] translate-y-[-50%] h-[30px] w-[30px]"
            >
              <path
                fill="#56e881"
                d="M8 5a2 2 0 0 0-2 2v10a2 2 0 1 0 4 0V7a2 2 0 0 0-2-2zm8 0a2 2 0 0 0-2 2v10a2 2 0 1 0 4 0V7a2 2 0 0 0-2-2z"
              />
            </svg>
          ) : (
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="absolute top-[50%] left-[50%] transform translate-x-[-50%] translate-y-[-50%] h-[30px] w-[30px]"
            >
              <path
                d="M16 8L2.5 15.7942L2.5 0.205771L16 8Z"
                fill="#56E881"
              />
            </svg>
          )}
        </div>
      )}
      <div className="flex items-center absolute bottom-0 w-full pl-xs pr-xl pb-[26px]">
        {isPlaying ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            id="Pause"
            width="16"
            height="16"
            onClick={onPlayPause}
            className="cursor-pointer"
          >
            <path
              fill="#56e881"
              d="M8 5a2 2 0 0 0-2 2v10a2 2 0 1 0 4 0V7a2 2 0 0 0-2-2zm8 0a2 2 0 0 0-2 2v10a2 2 0 1 0 4 0V7a2 2 0 0 0-2-2z"
            />
          </svg>
        ) : (
          <svg
            onClick={onPlayPause}
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="cursor-pointer"
          >
            <path
              d="M16 8L2.5 15.7942L2.5 0.205771L16 8Z"
              fill="#56E881"
            />
          </svg>
        )}

        {loading ? (
          <div className="loading">Loading...</div>
        ) : (
          <>
            <div
              className="w-full h-[8px] bg-white30 relative ml-xs rounded-[4px] cursor-pointer"
              onClick={handleProgressBarClick}
              ref={progressBarRef}
            >
              {playerRef?.current?.isReady && (
                <div
                  className="h-full w-[0px] absolute top-0 transition-all duration-100 ease-in bg-white50 rounded-[4px]"
                  style={{ width: `${(played * 100).toFixed(0)}%` }}
                />
              )}
              <div
                className="h-full w-[0px] absolute top-0 transition-all duration-100 ease-in bg-white50 rounded-[4px]"
                style={{ width: `${(played * 100).toFixed(0)}%` }}
              >
                <div className="h-[8px] w-[8px] bg-white rounded-full absolute right-0" />
              </div>
            </div>
            <p className="absolute left-[12px] bottom-[8px] text-white text-xs leading-xs font-semibold">
              {formatDuration(startTime)}
            </p>
            <p className="absolute right-[12px] bottom-[8px] text-white text-xs leading-xs font-semiboldv">
              {formatDuration(playerRef?.current?.getDuration())}
            </p>
          </>
        )}
      </div>
    </>
  );
};
