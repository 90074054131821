'use client';
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useRef, useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import { Spinner } from '@/components/Loadings';
import { Controls } from '@/components/VideoPlayer/Controls';
import { OnProgressProps } from 'react-player/base';

export const VideoPlayer = ({ src }: { src: string }) => {
  const playerRef = useRef(null);
  const progressBarRef = useRef(null);

  const [isPlaying, setIsPlaying] = useState(false);
  const [isYouTubeVideo, setIsYouTubeVideo] = useState(false);
  const [played, setPlayed] = useState(0);
  const [startTime, setStartTime] = useState(0);
  const [loading, setLoading] = useState(true);
  const [client, setClient] = useState(false);
  const [icon, setIcon] = useState(true);

  useEffect(() => {
    setClient(true);
  }, []);

  useEffect(() => {
    if (src.includes('youtube.com') || src.includes('youtu.be')) {
      setIsYouTubeVideo(true);
    }
  }, [src]);

  const onPlayPause = () => {
    if (isPlaying) {
      setIsPlaying(false);
      setIcon(true);
    } else {
      setIsPlaying(true);
      setTimeout(() => setIcon(false), 5000);
    }
  };

  const handleProgress = (state: OnProgressProps) => {
    setPlayed(state.played);
    setStartTime(state.playedSeconds);
  };

  const handleProgressBarClick = (e: React.MouseEvent<HTMLElement>) => {
    //@ts-expect-error
    const progressBarRect = progressBarRef?.current?.getBoundingClientRect();
    const clickPosition = e.clientX - progressBarRect.left;
    const percentageClicked = clickPosition / progressBarRect.width;
    //@ts-expect-error
    const newTime = percentageClicked * playerRef?.current?.getDuration();

    if (!isNaN(newTime) && isFinite(newTime)) {
      //@ts-expect-error
      playerRef?.current?.seekTo(newTime, 'seconds');
    }
  };

  const handleReady = () => {
    setLoading(false);
  };

  return (
    <div
      data-testid="video"
      className={`
        relative bg-black group w-[calc(100%-30px)] h-full shadow-2xl
        rounded-[8px] container mx-auto max-w-[1376px] p-0 react-player z-10
        aspect-video
    `}
    >
      {client ? (
        <>
          <div
            className="
              relative flex items-center justify-center w-full h-full aspect-video rounded-[8px]"
            onClick={onPlayPause}
          >
            <ReactPlayer
              ref={playerRef}
              url={src}
              playing={isPlaying}
              className="absolute w-full h-full rounded-[8px] aspect-video"
              width="100%"
              height="100%"
              onProgress={handleProgress}
              onReady={handleReady}
              onEnded={() => setIsPlaying(false)}
              onBuffer={() => setLoading(true)}
              onBufferEnd={() => setLoading(false)}
              progressInterval={100}
              controls={isYouTubeVideo}
            />
          </div>
          {!isYouTubeVideo && (
            <Controls
              onPlayPause={onPlayPause}
              handleProgressBarClick={handleProgressBarClick}
              playerRef={playerRef}
              played={played}
              progressBarRef={progressBarRef}
              startTime={startTime}
              icon={icon}
              loading={loading}
              isPlaying={isPlaying}
            />
          )}
        </>
      ) : (
        <div className="relative transform mx-auto w-full h-full">
          <Spinner />
        </div>
      )}
    </div>
  );
};
